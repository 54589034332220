<template>
  <a-menu style="width: 250px" :selectedKeys="selectedKeys" mode="inline" theme="light">
    <a-menu-item v-for="(item) in list" :key="item.route">
        <router-link :to="item.route">
              {{item.menuName}}
        </router-link>
    </a-menu-item>
  </a-menu>
</template>

<script>
export default {
    data() {
        return {
            selectedKeys: [],
            list: [
                // {menuName: '个人中心', route: '/account-center'},
                {menuName: '账户设置', route: '/account-center/settings'},
                {menuName: '自然人列表', route: '/account-center/natural'},
                {menuName: '店铺列表', route: '/account-center/store'}]
        };
    },
    mounted () {
        this.updateMenu()
    },
    watch: {
    '$route' () {
        this.updateMenu()
        }
    },
    methods: {
        handleClick() {
            this.updateMenu()
        },
        updateMenu () {
            const routes = this.$route.matched.concat()
            this.selectedKeys = [ routes.pop().path ]
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .ant-menu-item {
    &:nth-child(1) {
        margin-top: 0;
    }
}
/deep/ .ant-menu-item {
    a {
        color: rgba(0, 0, 0, 0.65);
    }
}
/deep/ .ant-menu-item-selected {
    a {
        color: @primary;
    }
}
</style>